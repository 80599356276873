.customToolTip {
  border-radius: 25px;
  background-color: #202020;
  color: white;
  padding: 30px 25px;
  min-width: 240px;
  position: relative;
  text-align: left;

  @media (max-width: 1366px) {
    padding: 25px;
  }

  p {
    margin: 0;

    @media (max-width: 1366px) {
      font-size: 11.5px;
    }
  }

  //&:after {
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background: #202020;
  //  width: 25px;
  //  height: 25px;
  //  border-radius: 5px;
  //  transform: rotate(45deg);
  //  z-index: 0;
  //}

  &.topLeft {
    &::after {
      top: 30px;
      left: -8px;
    }
  }

  &.bottomLeft {
    &::after {
      bottom: 30px;
      left: -8px;
      top: auto;
    }
  }

  &.bottomRight {
    &:after {
      bottom: 30px;
      right: -8px;
      top: auto;
      left: auto;
    }
  }

  &.bottomDown {
    &::after {
      top: auto;
      bottom: -10px;
      left: 30px;
    }
  }

  button {
    width: 90px;
    height: 35px;
    line-height: 1;
    font-size: 14px;
    margin-top: 22px;
  }
}


.toolTipWrapper {
  position: relative;
  margin-bottom: -3px;
  z-index: 10;

  &:hover {
    cursor: pointer;

    .toolTipBaloon {
      opacity: 1;
      visibility: visible;
    }

  }

  .toolTipBaloon {
    position: absolute;
    left: -200%;
    top: 150%;
    background: black;
    color: white;
    padding: 20px;
    border-radius: 25px;
    width: 300px;
    min-width: 300px;
    font-size: 12px;
    line-height: 1.25;
    z-index: 55;
    opacity: 0;
    visibility: hidden;
    transition: 1s ease;

    &:after {
      content: "";
      position: absolute;
      top: -7px;
      left: 55px;
      width: 20px;
      height: 20px;
      background: black;
      border-radius: 5px;
      transform: rotate(45deg);
    }
  }
}