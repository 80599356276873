.music-player-main {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: var(--background);

  button {
    background: transparent;
    width: auto !important;
    border: 0;
    box-shadow: unset;
    box-shadow: unset;
    width: 40px !important;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 35px;

    &.rhap_play-pause-button {
      width: 62px !important;
      height: 62px !important;
      background: white;
      font-size: 26px;
      color: $theme-orange;
    }
  }

  .rhap_volume-bar-area {
    display: none !important;
  }

  .rhap_volume-container {
    justify-content: flex-end;
  }

  .rhap_container {
    background: var(--background);
    border-radius: 10px;
    box-shadow: none;

    .rhap_main-controls {
      gap: 15px;
    }

    .rhap_current-time {
      color: #c4c4c4;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.56px;
      min-width: 35px;
      width: 35px;
      text-align: left;
    }

    .rhap_volume-container {
      button {
        svg {
          path {
            fill: #fff;
          }
        }
      }

      .rhap_volume-bar {
        .rhap_volume-indicator {
          background: linear-gradient(
            180deg,
            #f5b640 0%,
            #e59b0c 49.48%,
            #e26528 100%
          );
        }
      }
    }

    .rhap_total-time {
      color: #c4c4c4;
      min-width: 35px;
      width: 35px;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.56px;
    }

    .rhap_repeat-button {
      //color: white;
      font-size: 22px;
    }

    .rhap_volume-button {
      color: white;
    }

    .rhap_rewind-button,
    .rhap_forward-button {
      background: #9696966c;
      font-size: 24px;
    }

    .rhap_progress-section {
      margin-top: 15px;
      gap: 13px;

      .rhap_progress-bar {
        .rhap_download-progress {
          background-color: #6f6f6f;
        }

        .rhap_progress-filled {
          background: linear-gradient(
            181.17deg,
            #fd3051 0.91%,
            #ffa42e 163.51%
          );
        }

        .rhap_progress-indicator {
          background: white;
        }
      }
    }
  }
}
.test {
}
