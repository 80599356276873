/*
********************************************
* Global Color Theme
********************************************
*/

$theme-blue: #141c4a;
$theme-black: #202020;
$theme-dark-grey: #202020;
$theme-grey: #979797;
$theme-light-grey: #ededed;
$theme-orange: #ff574c;
$theme-background: #f4f9fb;
$theme-white: #ffffff;
$theme-border: #e8e8e8;
$theme-card: #fbfbfd;

/*
********************************************
* Toast
********************************************
*/

.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify__close-button {
  background: transparent !important;
  display: flex;

  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
}

/*
********************************************
* Scrollbar Style
********************************************
*/

::-webkit-scrollbar {
  width: 5px;
  // display: none;
}

::-webkit-scrollbar-track {
  background: $theme-white;
}

::-webkit-scrollbar-thumb {
  background: $theme-grey;
}

::-webkit-scrollbar-thumb:hover {
  background: $theme-black;
}

/*
********************************************
* Number Input Style
********************************************
*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
********************************************
* Global Style
********************************************
*/
html {
  scroll-behavior: smooth;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.text-red {
  color: red;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn {
  box-shadow: none !important;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }
}

input,
.form-control {
  font-size: 12px;
  height: 55px;
  border-radius: 10px !important;
  padding: 0 1rem;
  border: 1px solid #e8e8e8;
  box-shadow: none;
  outline: none;

  &::placeholder {
    color: $theme-grey;
    font-weight: 300;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $theme-orange;
  }
}

.input-group {
  border-radius: 10px;
  transition: all 0.5s ease;

  .input-group-text {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: transparent !important;
    border: 0;
    z-index: 555;
    background-color: $theme-white;
    border: none;
    border-radius: 10px;
  }
}

.breadcrumb {
  margin-bottom: 0;

  ol {
    margin-bottom: 0;
  }

  .breadcrumb-item {
    font-size: 12px;
    color: $theme-grey;
  }

  a {
    color: $theme-black;
    font-size: 12px;
    font-weight: 500;
  }
}

.nav-pills {
  .nav-link {
    &.active {
      background-color: transparent;

      .my-card {
        border: 1px solid $theme-orange;

        .music-bar-colored {
          display: block;
        }

        .music-bar-without-colored {
          display: none;
        }
      }
    }
  }
}

/*
********************************************
* Theme Font Size, Family And Weight 
********************************************
*/

h2,
.h2 {
  font-size: 36px;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 20px;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 18px;
  font-weight: 400;
}

p,
span,
.form-label {
  font-size: 14px;
  font-weight: 400;
}

a,
.nav-link {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

small {
  font-size: 12px;
  font-weight: 400;
}

ul {
  li {
    font-size: 12px;
  }
}

button,
.btn,
.btn-primary {
  font-size: 18px;
  height: 55px;
  font-weight: 600;
  background: linear-gradient(181.17deg, #fd3051 0.91%, #ffa42e 163.51%);
  border: 1px solid transparent;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  transition: all 0.5s ease;

  &:hover,
  &:focus,
  &:focus-within {
    // color: $theme-black;
    // background: transparent;
    background: linear-gradient(181.17deg, #ff1d43 0.91%, #ff9c1b 163.51%);
    border: 1px solid $theme-orange;
    box-shadow: none;
    outline: none;
  }
}

button:disabled,
button[disabled] {
  outline: none;
  box-shadow: none;
  border: 1px solid $theme-orange !important;
}

//  Magterial UI Data Grid
.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight,
.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiDataGrid-iconSeparator .css-i4bv87-MuiSvgIcon-root {
  display: none !important;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: $theme-blue;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: $theme-background;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.css-1w5m2wr-MuiDataGrid-virtualScroller {
  &::-webkit-scrollbar {
    // width: 5px;
    display: none;
  }
}

.css-1wpppbf-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-9y7lp4 .MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.css-9y7lp4 .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.css-1wpppbf-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.css-9y7lp4 .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.css-1yy0dv2 {
  display: none !important;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  border: none !important;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-1grl8tv {
  &::-webkit-scrollbar {
    display: none;
  }
}

//  hide arrow for sort
.css-ltf0zy-MuiDataGrid-iconButtonContainer {
  display: none !important;
}

//  Magterial UI Data Grid

.cursor-pointer {
  &:hover {
    cursor: pointer !important;
  }
}

.blue-color {
  color: $theme-blue;
}

.orange-color {
  color: $theme-orange;
}

.fw-500 {
  font-weight: 500 !important;
}

.custom_switch {
  padding: 0;
  gap: 10px;

  label {
    display: flex;
    font-size: 18px;
    color: #979797;

    &.active {
      color: #141c4a;
      font-weight: 600;
    }
  }

  .form-switch {
    padding: 0;
  }

  .form-check-input {
    margin: 0;
    vertical-align: none;
    height: 27px;
    width: 45px;
    background-color: #fd3051;
    border: 0;
    border-radius: 2em !important;
    transform: background-position 2s ease-in-out;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      box-shadow: none;
    }
  }
}

.sec-head {
  font-size: 18px;
  color: #141c4a;
  font-weight: 500;
}

.btn-transparent {
  background: transparent;
  border: 1px solid #ff574c;
  color: #ff574c;

  &:hover {
    cursor: pointer;
  }
}

.form-text {
  color: red;
}

.RowCursorPointer {
  div[role="row"] {
    &:hover {
      cursor: pointer;
    }
  }
}

.opacity-75 {
  opacity: 0.75;
}