@media (min-width: 576px) { 
    .me-sm-0{
        margin-right: 0;
    }

    .me-sm-1{
        margin-right: 0.25rem;
    }

    .me-sm-2{
        margin-right: 0.5rem;
    }

    .me-sm-3{
        margin-right: 1rem;
    }

    .me-sm-4{
        margin-right: 1.5rem;
    }

    .me-sm-5{
        margin-right: 3rem;
    }

    .ms-sm-0{
        margin-left: 0;
    }

    .ms-sm-1{
        margin-left: 0.25rem
    }

    .ms-sm-2{
        margin-left: 0.5rem;
    }

    .ms-sm-3{
        margin-left: 1rem; 
    }

    .ms-sm-4{
        margin-left: 1.5rem;
    }

    .ms-sm-5{
        margin-left: 3rem;
    }

    .mt-sm-0{
        margin-top: 0; 
    }

    .mt-sm-1{
        margin-top: 0.25rem; 
    }

    .mt-sm-2{
        margin-top: 0.5rem; 
    }

    .mt-sm-3{
        margin-top: 1rem; 
    }

    .mt-sm-4{
        margin-top: 1.5rem; 
    }

    .mt-sm-5{
        margin-top: 3rem; 
    }

    .mb-sm-0{
        margin-bottom: 0; 
    }

    .mb-sm-1{
        margin-bottom: 0.25rem; 
    }

    .mb-sm-2{
        margin-bottom: 0.5rem; 
    }

    .mb-sm-3{
        margin-bottom: 1rem; 
    }

    .mb-sm-4{
        margin-bottom: 1.5rem; 
    }

    .mb-sm-5{
        margin-bottom: 3rem; 
    }

    .pe-sm-0{
        padding-right: 0;
    }

    .pe-sm-1{
        padding-right: 0.25rem;
    }

    .pe-sm-2{
        padding-right: 0.5rem;
    }

    .pe-sm-3{
        padding-right: 1rem;
    }

    .pe-sm-4{
        padding-right: 1.5rem;
    }

    .pe-sm-5{
        padding-right: 3rem;
    }

    .ps-sm-0{
        padding-left: 0;
    }

    .ps-sm-1{
        padding-left: 0.25rem
    }

    .ps-sm-2{
        padding-left: 0.5rem;
    }

    .ps-sm-3{
        padding-left: 1rem; 
    }

    .ps-sm-4{
        padding-left: 1.5rem;
    }

    .ps-sm-5{
        padding-left: 3rem;
    }

    .pt-sm-0{
        padding-top: 0; 
    }

    .pt-sm-1{
        padding-top: 0.25rem; 
    }

    .pt-sm-2{
        padding-top: 0.5rem; 
    }

    .pt-sm-3{
        padding-top: 1rem; 
    }

    .pt-sm-4{
        padding-top: 1.5rem; 
    }

    .pt-sm-5{
        padding-top: 3rem; 
    }

    .pb-sm-0{
        padding-bottom: 0; 
    }

    .pb-sm-1{
        padding-bottom: 0.25rem; 
    }

    .pb-sm-2{
        padding-bottom: 0.5rem; 
    }

    .pb-sm-3{
        padding-bottom: 1rem; 
    }

    .pb-sm-4{
        padding-bottom: 1.5rem; 
    }

    .pb-sm-5{
        padding-bottom: 3rem; 
    }
}

@media (min-width: 768px) { 
    .me-md-0{
        margin-right: 0;
    }

    .me-md-1{
        margin-right: 0.25rem;
    }

    .me-md-2{
        margin-right: 0.5rem;
    }

    .me-md-3{
        margin-right: 1rem;
    }

    .me-md-4{
        margin-right: 1.5rem;
    }

    .me-md-5{
        margin-right: 3rem;
    }

    .ms-md-0{
        margin-left: 0;
    }

    .ms-md-1{
        margin-left: 0.25rem
    }

    .ms-md-2{
        margin-left: 0.5rem;
    }

    .ms-md-3{
        margin-left: 1rem; 
    }

    .ms-md-4{
        margin-left: 1.5rem;
    }

    .ms-md-5{
        margin-left: 3rem;
    }

    .mt-md-0{
        margin-top: 0; 
    }

    .mt-md-1{
        margin-top: 0.25rem; 
    }

    .mt-md-2{
        margin-top: 0.5rem; 
    }

    .mt-md-3{
        margin-top: 1rem; 
    }

    .mt-md-4{
        margin-top: 1.5rem; 
    }

    .mt-md-5{
        margin-top: 3rem; 
    }

    .mb-md-0{
        margin-bottom: 0; 
    }

    .mb-md-1{
        margin-bottom: 0.25rem; 
    }

    .mb-md-2{
        margin-bottom: 0.5rem; 
    }

    .mb-md-3{
        margin-bottom: 1rem; 
    }

    .mb-md-4{
        margin-bottom: 1.5rem; 
    }

    .mb-md-5{
        margin-bottom: 3rem; 
    }

    .pe-md-0{
        padding-right: 0;
    }

    .pe-md-1{
        padding-right: 0.25rem;
    }

    .pe-md-2{
        padding-right: 0.5rem;
    }

    .pe-md-3{
        padding-right: 1rem;
    }

    .pe-md-4{
        padding-right: 1.5rem;
    }

    .pe-md-5{
        padding-right: 3rem;
    }

    .ps-md-0{
        padding-left: 0;
    }

    .ps-md-1{
        padding-left: 0.25rem
    }

    .ps-md-2{
        padding-left: 0.5rem;
    }

    .ps-md-3{
        padding-left: 1rem; 
    }

    .ps-md-4{
        padding-left: 1.5rem;
    }

    .ps-md-5{
        padding-left: 3rem;
    }

    .pt-md-0{
        padding-top: 0; 
    }

    .pt-md-1{
        padding-top: 0.25rem; 
    }

    .pt-md-2{
        padding-top: 0.5rem; 
    }

    .pt-md-3{
        padding-top: 1rem; 
    }

    .pt-md-4{
        padding-top: 1.5rem; 
    }

    .pt-md-5{
        padding-top: 3rem; 
    }

    .pb-md-0{
        padding-bottom: 0; 
    }

    .pb-md-1{
        padding-bottom: 0.25rem; 
    }

    .pb-md-2{
        padding-bottom: 0.5rem; 
    }

    .pb-md-3{
        padding-bottom: 1rem; 
    }

    .pb-md-4{
        padding-bottom: 1.5rem; 
    }

    .pb-md-5{
        padding-bottom: 3rem; 
    }
}

@media (min-width: 992px) { 
    .me-lg-0{
        margin-right: 0;
    }

    .me-lg-1{
        margin-right: 0.25rem;
    }

    .me-lg-2{
        margin-right: 0.5rem;
    }

    .me-lg-3{
        margin-right: 1rem;
    }

    .me-lg-4{
        margin-right: 1.5rem;
    }

    .me-lg-5{
        margin-right: 3rem;
    }

    .ms-lg-0{
        margin-left: 0;
    }

    .ms-lg-1{
        margin-left: 0.25rem
    }

    .ms-lg-2{
        margin-left: 0.5rem;
    }

    .ms-lg-3{
        margin-left: 1rem; 
    }

    .ms-lg-4{
        margin-left: 1.5rem;
    }

    .ms-lg-5{
        margin-left: 3rem;
    }

    .mt-lg-0{
        margin-top: 0; 
    }

    .mt-lg-1{
        margin-top: 0.25rem; 
    }

    .mt-lg-2{
        margin-top: 0.5rem; 
    }

    .mt-lg-3{
        margin-top: 1rem; 
    }

    .mt-lg-4{
        margin-top: 1.5rem; 
    }

    .mt-lg-5{
        margin-top: 3rem; 
    }

    .mb-lg-0{
        margin-bottom: 0; 
    }

    .mb-lg-1{
        margin-bottom: 0.25rem; 
    }

    .mb-lg-2{
        margin-bottom: 0.5rem; 
    }

    .mb-lg-3{
        margin-bottom: 1rem; 
    }

    .mb-lg-4{
        margin-bottom: 1.5rem; 
    }

    .mb-lg-5{
        margin-bottom: 3rem; 
    }

    .pe-lg-0{
        padding-right: 0;
    }

    .pe-lg-1{
        padding-right: 0.25rem;
    }

    .pe-lg-2{
        padding-right: 0.5rem;
    }

    .pe-lg-3{
        padding-right: 1rem;
    }

    .pe-lg-4{
        padding-right: 1.5rem;
    }

    .pe-lg-5{
        padding-right: 3rem;
    }

    .ps-lg-0{
        padding-left: 0;
    }

    .ps-lg-1{
        padding-left: 0.25rem
    }

    .ps-lg-2{
        padding-left: 0.5rem;
    }

    .ps-lg-3{
        padding-left: 1rem; 
    }

    .ps-lg-4{
        padding-left: 1.5rem;
    }

    .ps-lg-5{
        padding-left: 3rem;
    }

    .pt-lg-0{
        padding-top: 0; 
    }

    .pt-lg-1{
        padding-top: 0.25rem; 
    }

    .pt-lg-2{
        padding-top: 0.5rem; 
    }

    .pt-lg-3{
        padding-top: 1rem; 
    }

    .pt-lg-4{
        padding-top: 1.5rem; 
    }

    .pt-lg-5{
        padding-top: 3rem; 
    }

    .pb-lg-0{
        padding-bottom: 0; 
    }

    .pb-lg-1{
        padding-bottom: 0.25rem; 
    }

    .pb-lg-2{
        padding-bottom: 0.5rem; 
    }

    .pb-lg-3{
        padding-bottom: 1rem; 
    }

    .pb-lg-4{
        padding-bottom: 1.5rem; 
    }

    .pb-lg-5{
        padding-bottom: 3rem; 
    }
}

@media (min-width: 1200px) { 
    .me-xl-0{
        margin-right: 0;
    }

    .me-xl-1{
        margin-right: 0.25rem;
    }

    .me-xl-2{
        margin-right: 0.5rem;
    }

    .me-xl-3{
        margin-right: 1rem;
    }

    .me-xl-4{
        margin-right: 1.5rem;
    }

    .me-xl-5{
        margin-right: 3rem;
    }

    .ms-xl-0{
        margin-left: 0;
    }

    .ms-xl-1{
        margin-left: 0.25rem
    }

    .ms-xl-2{
        margin-left: 0.5rem;
    }

    .ms-xl-3{
        margin-left: 1rem; 
    }

    .ms-xl-4{
        margin-left: 1.5rem;
    }

    .ms-xl-5{
        margin-left: 3rem;
    }

    .mt-xl-0{
        margin-top: 0; 
    }

    .mt-xl-1{
        margin-top: 0.25rem; 
    }

    .mt-xl-2{
        margin-top: 0.5rem; 
    }

    .mt-xl-3{
        margin-top: 1rem; 
    }

    .mt-xl-4{
        margin-top: 1.5rem; 
    }

    .mt-xl-5{
        margin-top: 3rem; 
    }

    .mb-xl-0{
        margin-bottom: 0; 
    }

    .mb-xl-1{
        margin-bottom: 0.25rem; 
    }

    .mb-xl-2{
        margin-bottom: 0.5rem; 
    }

    .mb-xl-3{
        margin-bottom: 1rem; 
    }

    .mb-xl-4{
        margin-bottom: 1.5rem; 
    }

    .mb-xl-5{
        margin-bottom: 3rem; 
    }

    .pe-xl-0{
        padding-right: 0;
    }

    .pe-xl-1{
        padding-right: 0.25rem;
    }

    .pe-xl-2{
        padding-right: 0.5rem;
    }

    .pe-xl-3{
        padding-right: 1rem;
    }

    .pe-xl-4{
        padding-right: 1.5rem;
    }

    .pe-xl-5{
        padding-right: 3rem;
    }

    .ps-xl-0{
        padding-left: 0;
    }

    .ps-xl-1{
        padding-left: 0.25rem
    }

    .ps-xl-2{
        padding-left: 0.5rem;
    }

    .ps-xl-3{
        padding-left: 1rem; 
    }

    .ps-xl-4{
        padding-left: 1.5rem;
    }

    .ps-xl-5{
        padding-left: 3rem;
    }

    .pt-xl-0{
        padding-top: 0; 
    }

    .pt-xl-1{
        padding-top: 0.25rem; 
    }

    .pt-xl-2{
        padding-top: 0.5rem; 
    }

    .pt-xl-3{
        padding-top: 1rem; 
    }

    .pt-xl-4{
        padding-top: 1.5rem; 
    }

    .pt-xl-5{
        padding-top: 3rem; 
    }

    .pb-xl-0{
        padding-bottom: 0; 
    }

    .pb-xl-1{
        padding-bottom: 0.25rem; 
    }

    .pb-xl-2{
        padding-bottom: 0.5rem; 
    }

    .pb-xl-3{
        padding-bottom: 1rem; 
    }

    .pb-xl-4{
        padding-bottom: 1.5rem; 
    }

    .pb-xl-5{
        padding-bottom: 3rem; 
    }
}

@media (min-width: 1400px) { 
    .me-xxl-0{
        margin-right: 0;
    }

    .me-xxl-1{
        margin-right: 0.25rem;
    }

    .me-xxl-2{
        margin-right: 0.5rem;
    }

    .me-xxl-3{
        margin-right: 1rem;
    }

    .me-xxl-4{
        margin-right: 1.5rem;
    }

    .me-xxl-5{
        margin-right: 3rem;
    }

    .ms-xxl-0{
        margin-left: 0;
    }

    .ms-xxl-1{
        margin-left: 0.25rem
    }

    .ms-xxl-2{
        margin-left: 0.5rem;
    }

    .ms-xxl-3{
        margin-left: 1rem; 
    }

    .ms-xxl-4{
        margin-left: 1.5rem;
    }

    .ms-xxl-5{
        margin-left: 3rem;
    }

    .mt-xxl-0{
        margin-top: 0; 
    }

    .mt-xxl-1{
        margin-top: 0.25rem; 
    }

    .mt-xxl-2{
        margin-top: 0.5rem; 
    }

    .mt-xxl-3{
        margin-top: 1rem; 
    }

    .mt-xxl-4{
        margin-top: 1.5rem; 
    }

    .mt-xxl-5{
        margin-top: 3rem; 
    }

    .mb-xxl-0{
        margin-bottom: 0; 
    }

    .mb-xxl-1{
        margin-bottom: 0.25rem; 
    }

    .mb-xxl-2{
        margin-bottom: 0.5rem; 
    }

    .mb-xxl-3{
        margin-bottom: 1rem; 
    }

    .mb-xxl-4{
        margin-bottom: 1.5rem; 
    }

    .mb-xxl-5{
        margin-bottom: 3rem; 
    }

    .pe-xxl-0{
        padding-right: 0;
    }

    .pe-xxl-1{
        padding-right: 0.25rem;
    }

    .pe-xxl-2{
        padding-right: 0.5rem;
    }

    .pe-xxl-3{
        padding-right: 1rem;
    }

    .pe-xxl-4{
        padding-right: 1.5rem;
    }

    .pe-xxl-5{
        padding-right: 3rem;
    }

    .ps-xxl-0{
        padding-left: 0;
    }

    .ps-xxl-1{
        padding-left: 0.25rem
    }

    .ps-xxl-2{
        padding-left: 0.5rem;
    }

    .ps-xxl-3{
        padding-left: 1rem; 
    }

    .ps-xxl-4{
        padding-left: 1.5rem;
    }

    .ps-xxl-5{
        padding-left: 3rem;
    }

    .pt-xxl-0{
        padding-top: 0; 
    }

    .pt-xxl-1{
        padding-top: 0.25rem; 
    }

    .pt-xxl-2{
        padding-top: 0.5rem; 
    }

    .pt-xxl-3{
        padding-top: 1rem; 
    }

    .pt-xxl-4{
        padding-top: 1.5rem; 
    }

    .pt-xxl-5{
        padding-top: 3rem; 
    }

    .pb-xxl-0{
        padding-bottom: 0; 
    }

    .pb-xxl-1{
        padding-bottom: 0.25rem; 
    }

    .pb-xxl-2{
        padding-bottom: 0.5rem; 
    }

    .pb-xxl-3{
        padding-bottom: 1rem; 
    }

    .pb-xxl-4{
        padding-bottom: 1.5rem; 
    }

    .pb-xxl-5{
        padding-bottom: 3rem; 
    }
}