.themeTabs {
  .ant-tabs-nav {
    margin-bottom: -2px;
  }

  .ant-tabs-nav-wrap {
    border: 0;
  }

  .ant-tabs-nav-list {
    border: 0;
    margin: 0;

    .ant-tabs-tab {
      justify-content: center;
      display: flex;
      margin-right: 10px !important;
      border-radius: 15px 15px 0px 0px;
      background: #f4f9fb;
      border-color: #f4f9fb;
      font-weight: 500;
      color: #141c4a;
      min-width: 113px;

      .ant-tabs-tab-btn {
        text-align: center;
      }

      &.ant-tabs-tab-active {
        background: linear-gradient(181.17deg, #fd3051 0.91%, #ffa42e 163.51%);
        color: white;

        .ant-tabs-tab-btn {
          color: white;
        }
      }
    }
  }
}
.ant-tabs-nav-more{
  display: none !important;
}