.step1 {
  position: absolute;
  top: 0;
  right: 1rem;

  .customToolTip {
    width: 360px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.mask-wrapper {
  position: relative;
  z-index: 7;
}

.step2 {
  position: absolute;
  top: 8%;
  left: 105%;
  width: max-content;
  right: 0;
}

.step3 {
  position: absolute;
  top: 0;
  left: 110%;

  .customToolTip {
    width: 380px;
  }

  &.new-lesson-step {
    @media (max-width: 1800px) {
      left: -110%;
      right: auto;
    }
    .customToolTip {
      &:after {
        @media (max-width: 1800px) {
          bottom: 30px;
          left: auto;
          top: auto;
          right: -8px;
        }
      }
    }
  }
}

.step4 {
  position: absolute;
  bottom: 135%;
  left: 70%;

  .customToolTip {
    width: 330px;
  }
}

.step-four-wrapper {
  position: relative;

  .step4_a {
    position: absolute;
    top: -45%;
    right: 115%;
    @media (max-width: 1366px) {
      top: -20%;
    }
  }

  .step4_b {
    position: absolute;
    bottom: 0;
    right: 115%;

    .customToolTip {
      width: 357px;
    }

    .border-gradient {
      font-size: 12px;
      border: 1px solid $theme-orange;
      padding: 12px 15px;
      border-radius: 10px;
      background: #2f2f2f;
    }
  }

  .step4_c {
    position: absolute;
    top: -15%;
    bottom: auto;
    left: 115%;
    right: 0;

    .customToolTip {
      width: 357px;
    }
  }

  .step4_d {
    position: absolute;
    bottom: -35%;
    left: 115%;
    right: 0;

    @media (max-width: 1366px) {
      bottom: 0;
    }

    .customToolTip {
      width: 357px;
    }
  }
}
