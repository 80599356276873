.wrapperLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}