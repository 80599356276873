@import "scss/_theme.scss";
@import "scss/_auth-layout.scss";
@import "scss/_full-layout.scss";
@import "scss/_main-layout.scss";
@import "scss/_sub-admin-full-layout.scss";
@import "scss/_header.scss";
@import "scss/_home.scss";
@import "scss/_popup.scss";
@import "scss/_media-query.scss";
@import "scss/_media-query-bootstrap.scss";
@import "scss/_forms.scss";
@import "scss/_professionals.scss";
@import "scss/_tabs.scss";
@import "scss/_modal.scss";
@import "scss/_audioPlayer.scss";
@import "scss/_videoPlayer.scss";
@import "scss/_verticalTabs.scss";
@import "scss/_customToolTip.scss";
@import "scss/_steps.scss";
@import "scss/_subscription_page.scss";
@import "scss/_loader.scss";
@import "scss/_editorTemplate.scss";


.custom-progress-bar .progress-bar {
  background-color: #ff5733 !important;
}
