.auth-layout {
    height: 100vh;
    overflow-y: hidden;

    .left {
        min-width: 480px;
        height: 100vh;

        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
    }

    .right {
        background-color: $theme-background;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;

        h2 {
            color: $theme-blue;
        }

        span {
            color: $theme-dark-grey;
        }

        .forms {
            border-radius: 25px;
            background-color: $theme-white;



            &.verification-code-form {
                h5 {
                    color: $theme-orange;
                }

                .resend-code {
                    color: $theme-orange;
                    text-decoration: underline;
                    cursor: pointer;
                }

                h6 {
                    color: $theme-black;
                }

                .heading {
                    h5 {
                        color: $theme-blue;
                    }
                }

                .btn-resend-code {
                    color: $theme-black;
                    background: transparent;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    &:hover,
                    &:focus {
                        border: none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            &.signup-form {
                a {
                    font-size: 14px;
                    color: $theme-orange;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.signin-form {
                .link {
                    font-weight: 500;
                    font-size: 14px;
                    color: $theme-orange;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                a,
                .nav-link {
                    color: $theme-black;
                }
            }

            &.forgot-password-form {
                h4 {
                    color: $theme-blue;
                }
            }
        }


        .otp-input {
            input {
                height: 50px;
                width: 100% !important;
                padding: 0;
                margin: 0 5px;
                border: 1px solid $theme-border;

                &:focus {
                    color: $theme-black;
                    border: 1px solid $theme-orange;
                }
            }
        }
    }
}