.header {
  border-bottom: 2px solid $theme-light-grey;

  .circle-div {
    position: relative;
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $theme-white;
    border-radius: 50%;

    .notify {
      width: 6px;
      height: 6px;
      position: absolute;
      background-color: $theme-orange;
      border-radius: 50%;
      top: 15px;
      right: 14px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }

  .headerBtn {
    height: 35px;
    font-size: 14px;
    width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.breadcrumb-item {
  &.home {
    a {
      color: #ff574c;
    }
  }
}
