/* CSS for radio buttons with parent class select_role */
.select_role input[type="radio"] {
    display: none;


}

.select_role {
    display: flex;
    flex-flow: column;
    gap: 11px;

    .form-check {
        padding: 0;
    }

    label {
        display: flex;
        align-items: center;
        border-color: 1px solid $theme-background;
        background: $theme-background;
        border-radius: 15px;
        font-size: 16px;
        font-weight: 500;
        height: 65px;
        border-radius: 10px;
        padding: 0 1rem;
        border: none;
        box-shadow: none;
        outline: none;
        border: 1px solid transparent;
        transition: 0.2s ease;

        @media(max-width: 1366px) {
            height: 45px;
            font-size: 12px;
        }

        &:hover {
            background: linear-gradient(181.17deg, rgba(253, 48, 81, 0.16) 0.91%, rgba(255, 164, 46, 0.16) 163.51%);
            border-color: $theme-orange;
            cursor: pointer;
        }
    }
}

.select_role input[type="radio"]:checked+label {
    background: linear-gradient(181.17deg, rgba(253, 48, 81, 0.16) 0.91%, rgba(255, 164, 46, 0.16) 163.51%);

    border-color: $theme-orange;
}

.form-group {
    margin-bottom: 15px;
}



.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0 !important;
}

.input-group {
    textarea.form-control {
        height: auto;
        min-height: calc(1.5em + (9.75rem + 2px));
        height: auto;
        padding: 15px;
        resize: none;

    }
}