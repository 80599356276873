.videoPlayerComponent {
    display: flex;
    flex-flow: column;

    .react-player {
        video {
            border-radius: 15px;
        }
    }

    .volumneControl {
        font-size: 24px;
    }

    input[type='range'] {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        background: #6F6F6F;
        border-radius: 5px;
        outline: none;
        border: none;
        margin: 10px 0;
        padding: 0;
    }

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border-radius: 50%;
        cursor: pointer;
    }

    input[type='range']::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #ffffff;
        border-radius: 50%;
        cursor: pointer;
    }

    /* For Firefox */
    input[type='range']::-moz-range-progress {
        background-color: transparent;
    }

    /* For Chrome */
    input[type='range']::-webkit-slider-runnable-track {
        background-color: transparent;
    }
}