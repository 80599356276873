.mediaModal {
  .modal-content {
    border: 0;
    background: transparent;
  }
}

.mediaModalClient {
  .modal-dialog {
    .modal-content {
      .modal_body {
        .heading_modal {
          color: black !important;
          font-size: 20px;
        }
      }
    }
  }

  .modal-content {
    border: 0;
    background: transparent;
  }
}

.darkModalWrapper {
  background-color: #202020;
  padding: 24px;
  display: flex;
  flex-flow: column;
  gap: 20px;
  border-radius: 25px;

  // --------- Image component --------
  .image_box {
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 457px;
    height: 262px;

    @media (max-width: 767px) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}