.ant-tabs-nav-list {
  margin-bottom: 18px;

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    border-bottom: 1px solid #141c4a33;
    margin: 0 !important;
    padding: 11px 0 !important;
    min-width: 264px;

    @media (max-width: 1366px) {
      padding: 8px 0 !important;
    }

    &:last-child {
      border-bottom: 0 !important;
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #ff574c;
      }
    }

    .ant-tabs-tab-btn {
      color: #141c4a;
      font-size: 16px;

      &:focus {
        color: #ff574c;
      }
    }
  }
}

.tabs-add-box {
  .ant-tabs {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #979797 !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #202020 !important;
    }
  }

  .ant-tabs-tab {
    width: 160px;
    word-break: break-word;
    display: inline-block;
    white-space: normal;
    text-align: left !important;
  }

  .ant-tabs-nav-list {
    min-width: 274px;

  }

  .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-content-holder {
    display: none;
  }
}

.openAddModal_Instep {
  color: #202020;

  &:hover {
    color: $theme-orange;
  }
}

.ant-tabs-content-holder {
  border-left: 0 !important;
}

.addnewContent {
  height: 540px;
  overflow: scroll;

  p {
    font-size: 13px;
    color: #202020;
  }
}

.addnewVideoPlayer {
  video {
    box-shadow: 0px 1px 20px 3px #141c4a85;
  }

  &:hover {
    .playPauseButton {
      opacity: 1;
    }
  }

  .playPauseButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff574c;
    color: white;
    opacity: 0;
    transition: 0.7s ease;

    &:hover {
      cursor: pointer;
    }
  }
}