.subscription_box {
  padding-bottom: 45px;
  @media (max-width: 1366px) {
    padding-bottom: 10px;
  }
  .heading {
    padding-bottom: 35px;
    @media (max-width: 1366px) {
      padding-bottom: 10px;
    }
    h6 {
      font-size: 20px;
      color: #141c4a;
      font-weight: 500;
      line-height: 1;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      color: #202020;
      line-height: 1;
    }
  }
}
