.editorTemplateContainer {
    border: 1px solid #c4c4c4;
    border-top: 0;
    border-radius: 0;
    min-height: 300px;
    padding: 8px;
    span,
    ul,
    ol,
    li {
        font-size: unset;
        font-family: unset;
        font-weight: unset;
        margin: 0;
    }
}


.editorToolbar {
    * {
        color: black;
    }
}

.rdw-editor-toolbar {
    border: 1px solid #c4c4c4;
    margin: 0;
    border-radius: 0;
}
.rdw-link-modal-target-option {
    margin-bottom: 0px !important;
    display: flex !important;
    align-items: center !important;
}
.rdw-link-modal-target-option > span {
    margin-left: 5px !important;
}
.rdw-link-modal {
    position: absolute !important;
    top: 35px !important;
    left: 5px !important;
    display: flex !important;
    flex-direction: column !important;
    width: 235px !important;
    height: 250px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 15px !important;
    border-radius: 2px !important;
    z-index: 100 !important;
    background: white !important;
    box-shadow: 3px 3px 5px #BFBDBD !important;
}
.rdw-link-modal-input {
    margin-top: 5px;
    border-radius: 2px !important;
    border: 1px solid #F1F1F1;
    height: 25px;
    margin-bottom: 15px;
    padding: 0 5px;
}
.rdw-link-modal-buttonsection {
    margin: 0 auto;
}

.rdw-link-modal-btn:disabled {
    background: #ece9e9;
    border: none !important;
    color: #aaa8a8 !important;
}

.rdw-link-modal-btn {
    margin-left: 10px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

.rdw-link-modal {
    .editorToolbar {
        display: flex;
    }
}
//.rdw-editor-toolbar.editorToolbar {
//    display: none;
//}

.rdw-editor-wrapper:focus-within .editorToolbar {
    display: flex;
}
